<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONTHLY PDC EXPENDITURE REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="['Current','Non Current']"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="option"
              class="mx-2"
              dense
              outlined
              label="Option"
              :items="option_items"
              :rules="rules.combobox_rule"
              @change="selected_option"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="option==='Particulars'">
            <v-select
              v-model="particulars_id"
              class="mx-2"
              dense
              outlined
              label="Particulars Data"
              :items="particulars_items"
              item-value="id"
              item-text="particulars"
              :rules="rules.combobox_rule"
              @change="save_request"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12
                  v-if="this.option==='Branch'||this.option==='Regional'||this.option==='Territory'">
            <v-select
              v-model="branch_id"
              class="mx-2"
              dense
              outlined
              label="Branch Data"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
              @change="save_request"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-form>
      <v-data-table dense
                    v-if="this.option==='Summary'"
                    class="mt-3"
                    :headers="headers"
                    :items="data_items"
                    disable-pagination
                    hide-default-footer
      >
        <template v-slot:top v-if="data_items.length>0">
          <h2 v-if="!saving_data" class="mx-2">PRINT
            <v-icon
              class="mr-2"
              color="success"
              @click="print_data"
            >
              {{icons.mdiPrinter}}
            </v-icon>
          </h2>

          <v-progress-circular
            :size=50
            :width="5"
            color="success"
            indeterminate
            v-else
          ></v-progress-circular>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.particulars }}
            </td>
            <td>
              {{item.cash_expense===0?'': formatPrice(item.cash_expense) }}
            </td>
            <td>
              {{item.pdc_expense===0?'': formatPrice(item.pdc_expense) }}
            </td>
            <td>
              {{item.auto_deb_expense===0?'': formatPrice(item.auto_deb_expense) }}
            </td>
            <td>
              {{item.total_expense===0?'': formatPrice(item.total_expense) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-data-table dense
                    v-if="this.option==='Particulars'||this.option==='Branch'||this.option==='Regional'||this.option==='Territory'"
                    class="mt-3"
                    :headers="headers2"
                    :items="data_items"
                    disable-pagination
                    hide-default-footer
      >
        <template v-slot:top v-if="data_items.length>0">
          <h2 v-if="!saving_data" class="mx-2">PRINT
            <v-icon
              class="mr-2"
              color="success"
              @click="print_data"
            >
              {{icons.mdiPrinter}}
            </v-icon>
          </h2>

          <v-progress-circular
            :size=50
            :width="5"
            color="success"
            indeterminate
            v-else
          ></v-progress-circular>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.voucher_no }}
            </td>
            <td>
              {{ item.branch }}
            </td>
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td>
              {{ item.credit_to }}
            </td>
            <td>
              {{item.cash===0?'': formatPrice(item.cash) }}
            </td>
            <td>
              {{item.cheque===0?'': formatPrice(item.cheque) }}
            </td>
            <td>
              {{item.auto_deb===0?'': formatPrice(item.auto_deb) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-data-table dense
                    v-if="this.option==='UnLiquidated'"
                    class="mt-3"
                    :headers="headers3"
                    :items="data_items"
                    disable-pagination
                    hide-default-footer
      >
        <template v-slot:top v-if="data_items.length>0">
          <h2 v-if="!saving_data" class="mx-2">PRINT
            <v-icon
              class="mr-2"
              color="success"
              @click="print_data"
            >
              {{icons.mdiPrinter}}
            </v-icon>
          </h2>

          <v-progress-circular
            :size=50
            :width="5"
            color="success"
            indeterminate
            v-else
          ></v-progress-circular>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.voucher_no }}
            </td>
            <td>
              {{ item.branch }}
            </td>
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.particulars }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td>
              {{ item.credit_to }}
            </td>
            <td>
              {{item.cash===0?'': formatPrice(item.cash) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      option_items: [],
      category_items: [],
      month_of_items: [],
      particulars_items: [],
      branch_items: [],
      data_items: [],
      category_id: '',
      branch_id: '',
      option: '',
      particulars_id: '',
      alert_message: '',
      selection: '',
      month_of_id: '',
      total_amount: '0.00',
      headers: [
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Cash Expense', value: 'last_name', sortable: false},
        {text: 'PDC Expense', value: 'last_name', sortable: false},
        {text: 'Auto Debit Expense', value: 'last_name', sortable: false},
        {text: 'Total Expense', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credited To', value: 'last_name', sortable: false},
        {text: 'Cash', value: 'last_name', sortable: false},
        {text: 'PDC', value: 'last_name', sortable: false},
        {text: 'Auto Debit', value: 'last_name', sortable: false},
      ],
      headers3: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credited To', value: 'last_name', sortable: false},
        {text: 'Cash', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('monthly_liquidation', ['list_of_category_have_epxense', 'monthly_expenditure', 'particulars_available_in_expenditure', 'branch_available_in_expenditure', 'list_of_request_unliquidated']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_selection() {
        this.data_items = []
        this.category_items = []
        this.particulars_items = []
        this.category_id = ''
        this.particulars_id = ''
        this.branch_id = ''
        this.option = ''
        if (this.selection != '') {
          await this.list_of_category_have_epxense({
            is_current: this.selection === 'Current',
            month_of_id: this.month_of_id
          })
            .then(response => {
              this.category_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
          await this.save_request()
        }
      },
      async selected_option() {
        this.data_items = []
        this.particulars_id = ''
        this.branch_id = ''
        if (this.option === 'Particulars') {
          await this.particulars_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_pdc: 1,
            is_auto_deb: 0,
          })
            .then(response => {
              this.particulars_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        } else if (this.option === 'Branch') {
          await this.branch_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_branch: this.option === 'Branch' ? 1 : 0,
            is_region: 0,
            is_territory: 0,
            is_pdc: 1,
            is_auto_deb: 0,
          })
            .then(response => {
              this.branch_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        } else if (this.option === 'Regional') {
          await this.branch_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_region: this.option === 'Regional' ? 1 : 0,
            is_branch: 0,
            is_territory: 0,
            is_pdc: 1,
            is_auto_deb: 0,
          })
            .then(response => {
              this.branch_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        } else if (this.option === 'Territory') {
          await this.branch_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_territory: this.option === 'Territory' ? 1 : 0,
            is_region: 0,
            is_branch: 0,
            is_pdc: 1,
            is_auto_deb: 0,
          })
            .then(response => {
              this.branch_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          await this.save_request()
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.data_items = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        if (this.$refs.form.validate()) {
          this.monthly_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            particulars_id: this.particulars_id,
            branch_id: this.branch_id,
            is_branch: this.option === 'Branch' ? 1 : 0,
            is_region: this.option === 'Regional' ? 1 : 0,
            is_territory: this.option === 'Territory' ? 1 : 0,
            is_pdc: 1,
            is_auto_deb: 0,
          })
            .then(response => {
              this.data_items = response.data
              this.saving_data = false
              this.alert = false
            })
            .catch(error => {
              console.log(error)
            });
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async selected_category(value) {
        this.data_items = []
        this.particulars_id = ''
        this.branch_id = ''
        this.option = ''
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.option_items = ['Summary', 'Particulars', 'Branch']
          if (this.category_id === 4 || this.category_id === 3) {
            this.option_items = ['Summary', 'Particulars', 'Branch', 'Regional', 'Territory']
          }
        }
        await this.save_request()
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []

          if (this.option === 'Summary') {
            widths = [200, 70, 70, 70, 70, 70]
            payments_array.push([
              {text: 'Particulars', alignment: 'left', style: 'main_info',},
              {text: 'Cash Expense', alignment: 'left', style: 'main_info'},
              {text: 'PDC Expense', alignment: 'left', style: 'main_info'},
              {text: 'Auto Debit Expense', alignment: 'left', style: 'main_info'},
              {text: 'Total Expense', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payments_array.push([
                {
                  text: item.particulars,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.cash_expense === 0 ? '' : (item.cash_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.pdc_expense === 0 ? '' : (item.pdc_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.auto_deb_expense === 0 ? '' : (item.auto_deb_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.total_expense === 0 ? '' : (item.total_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: true,
                },
              ])
            })
          } else if (this.option === 'Particulars' || this.option === 'Branch' || this.option === 'Regional' || this.option === 'Territory') {
            widths = [30, 50, 40, 150, 100, 40, 40, 40]
            payments_array.push([
              {text: 'Vouc #', alignment: 'left', style: 'main_info',},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Date', alignment: 'left', style: 'main_info'},
              {text: 'Description', alignment: 'left', style: 'main_info'},
              {text: 'Credited To', alignment: 'left', style: 'main_info'},
              {text: 'Cash', alignment: 'left', style: 'main_info'},
              {text: 'PDC', alignment: 'left', style: 'main_info'},
              {text: 'Auto Debit', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payments_array.push([
                {
                  text: item.voucher_no,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.branch,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.date,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.description,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.credit_to,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.cash === 0 ? '' : (item.cash / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: item.cheque === 0 ? '' : (item.cheque / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: item.auto_deb === 0 ? '' : (item.auto_deb / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
              ])
            })
          } else if (this.option === 'UnLiquidated') {
            widths = [30, 50, 40, 60, 180, 100, 40,]
            payments_array.push([
              {text: 'Vouc #', alignment: 'left', style: 'main_info',},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Date', alignment: 'left', style: 'main_info'},
              {text: 'Particulars', alignment: 'left', style: 'main_info'},
              {text: 'Description', alignment: 'left', style: 'main_info'},
              {text: 'Credited To', alignment: 'left', style: 'main_info'},
              {text: 'Cash', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payments_array.push([
                {
                  text: item.voucher_no,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.branch,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.date,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.particulars,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.description,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.credit_to,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.cash === 0 ? '' : (item.cash / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
              ])
            })
          }

          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    {
                      text: this.option === 'Summary' || this.option === 'UnLiquidated' ? this.option :
                        (this.option === 'Particulars' ? this.option + ' ( ' + this.particulars_items[this.particulars_items.map(function (x) {
                          return x.id;
                        }).indexOf(this.particulars_id)].particulars + ' )' :
                          (this.option === 'Branch' || this.option === 'Regional' || this.option === 'Territory' ? this.option + ' ( ' + this.branch_items[this.branch_items.map(function (x) {
                            return x.id;
                          }).indexOf(this.branch_id)].branch_code + ' )' : '')),
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Month of: ',
                    {
                      text: this.month_of_items[this.month_of_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.month_of_id)].month_of,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'GOODLIFE GROUP OF COMPANIES',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'MONTHLY PDC EXPENDITURE',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
